












import { Component, Vue } from 'vue-property-decorator'
import { Getter, namespace } from 'vuex-class'

import User from '@/models/user'
import Membership from '@/models/membership'
import MemberPreference from '@/models/member_preference'

import MemberPreferencesForm from './components/MemberPreferencesForm.vue'

Vue.component('MemberPreferencesForm', MemberPreferencesForm)

const Auth = namespace('auth')

@Component
export default class Preferences extends Vue {
  @Auth.Getter currentMemberId!: string

  currentUser: User = new User()
  currentMember: Membership = new Membership()
  currentMemberPreference: MemberPreference = new MemberPreference()

  async mounted () {
    await this.getCurrentMember()
    if (this.currentMember) {
      this.currentUser = this.currentMember.user
      this.currentMemberPreference = this.currentMember.memberPreference
    }
  }

  async getCurrentMember () {
    this.currentMember = (await Membership
      .includes('user')
      .includes('memberPreference')
      .find(this.currentMemberId)
    ).data
  }
}
